import gjfilter from "geojson-filter";

export default function filterResortByPass(geojson, passType) {
  if (passType == "noFilter") {
    return geojson;
  } else {
    const filter = ["==", "pass_type", passType];
    return gjfilter(geojson, filter);
  }
}
