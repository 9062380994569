export async function getSnowGeojson(daysOut) {
  return import(`../Assets/dissolved/dissolved-snow-co-${daysOut}.json`);
}

export async function getInitialResort() {
  return import(`../Assets/data/initial_resort.json`);
}

export async function getAllResorts() {
  return await import(`../Assets/data/resorts.json`);
  // console.log(data)
  // return data
}
